






















import {Component, Vue} from 'vue-property-decorator';

@Component
export default class Login extends Vue {
  private login = ''
  private password = ''
  private loading = false

  async loginBtnClicked() {
    this.loading = true;
    const response = await fetch('/api/v1/authorization', {
      body: JSON.stringify({login: this.login, password: this.password}),
      method: 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }
    });
    const data = await response.json();
    this.loading = false;

    if (data.token === '') {
      alert('Неправильный логин или пароль');
      this.login = '';
      this.password = '';
      return;
    }

    localStorage.setItem('token', data.token);
    this.$emit('login');
  }

}
