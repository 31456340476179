


































import Vue from 'vue';
import Home from '@/views/Home.vue';
import {Component} from 'vue-property-decorator';
import WorkerPage from '@/views/WorkerPage.vue';
import User from '@/models/User';
import UserLastPosition from '@/models/UserLastPosition';
import {addHours, parseJSON} from 'date-fns';
import LastPosition from '@/models/LastPosition';
import Point from '@/models/Point';
import WorkTask from '@/models/WorkTask';
import TaskPage from '@/views/TaskPage.vue';
import UserTrack from '@/models/UserTrack';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import Login from '@/views/Login.vue';

@Component({
  components: {
    Login,
    TaskPage,
    WorkerPage,
    Home,
  },
})
export default class App extends Vue {
  private screen = 'login';

  workers: User[] = [];

  selectedWorker: UserLastPosition | null = null

  tasks: WorkTask[] = []

  beforeMount() {
    const token = localStorage.getItem('token');

    if (token == null) {
      return;
    }

    Vue.nextTick(() =>{
      const decodedJwt = jwtDecode<JwtPayload>(token);

      if (Date.now() >= decodedJwt.exp! * 1000){
        this.screen = 'login';
        return;
      }

      this.screen = 'Home';

      this.getTasks();
      this.getUsers();
    });
  }

  login() {
    this.screen = 'Home';
  }

  getTasks() {
    fetch('/api/v1/tasks', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
        .then(async (data) => {
          return (await data.json()).inventoryTasks as Array<WorkTask>
        })
        .then((tasks) => {
          return tasks.map((t) => {
            const planningDate = parseJSON(t.planningDate);
            let finishDate: Date | null = null;

            if (t.finishDate != null) {
              finishDate = parseJSON(t.finishDate)
            }
            const timeZone = planningDate.getTimezoneOffset() / 60;

            return new WorkTask({
              id: t.id,
              status: t.status,
              title: t.title,
              description: t.description,
              finishDate: finishDate == null ? null : addHours(finishDate, timeZone),
              planningDate: addHours(planningDate, timeZone),
              user: new User({
                id: t.user.id,
                surname: t.user.surname,
                firstName: t.user.firstName,
                middleName: t.user.middleName,
              }),
              location: new Point({
                longitude: t.location.longitude,
                latitude: t.location.latitude,
              }),
            });
          })
        })
        .then((tasks) => {
          this.tasks = tasks;
        });
  }

  getUsers() {
    fetch('/api/v1/users', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
        .then(async (data) => {
          return (await data.json()).users as Array<User>
        })
        .then((users) => {
          return users.map((u) => new User({
                id: u.id,
                middleName: u.middleName,
                firstName: u.firstName,
                surname: u.surname,
                lastPosition: u.lastPosition == null ? null : new Point({
                  longitude: u.lastPosition.longitude,
                  latitude: u.lastPosition.latitude,
                })
              })
          );
        })
        .then((users) => {
          this.workers = users;
        });
  }

  workerSelected(worker: User){
    fetch(`/api/v1/users/${worker.id}/track`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(async (data) => {
      return (await data.json()).userTracks as Array<UserTrack>
    }).then((userTracks) => {
      return userTracks.map((ulp) => {
        const date = parseJSON(ulp.date);
        const timeZone = date.getTimezoneOffset() / 60;

        return new UserLastPosition({
          id: ulp.id,
          user: new User({
            id: ulp.user.id,
            surname: ulp.user.surname,
            firstName: ulp.user.firstName,
            middleName: ulp.user.middleName,
          }),
          lastPosition: new LastPosition({
            date: addHours(date, timeZone),
            location: new Point({
              latitude: ulp.location.latitude,
              longitude: ulp.location.longitude,
            }),
          }),
        });
      })
    })
    .then((usersLastPosition) => {
      this.selectedWorker = usersLastPosition[0];
      this.screen = 'Home';
    })
  }
}
