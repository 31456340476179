




















import Vue from "vue";
import Component from "vue-class-component";
import {DataTableHeader} from "vuetify";
import {Prop} from 'vue-property-decorator';
import WorkTask, {WorkTaskStatuses} from '@/models/WorkTask';

@Component
export default class TaskPage extends Vue {

  private tableHeaders!: DataTableHeader[]

  @Prop({required: true})
  private tasks!: WorkTask[]

  beforeMount(){
    this.tableHeaders = [
      {
        text: 'Задача',
        value: 'title'
      },
      {
        text: 'Описание',
        value: 'description'
      },
      {
        text: 'Статус',
        value: 'status'
      },
    ]
  }

  statusName(status: string): string {
    return WorkTaskStatuses.statusName(status);
  }
}
