













import {Component, Prop, Vue} from 'vue-property-decorator';
import {addHours, parseJSON} from 'date-fns';

import Map from '@/components/Map.vue';
import UserDetails from '@/components/UserDetails.vue';
import WorkTask from '@/models/WorkTask';
import Point from '@/models/Point';
import User from '@/models/User';
import ObjectDetails from '@/components/ObjectDetails.vue';
import InventoryObject from '@/models/InventoryObject';
import UserLastPosition from '@/models/UserLastPosition';
import LastPosition from '@/models/LastPosition';

@Component({
  components: {
    ObjectDetails,
    UserDetails,
    Map,
  },
})
export default class Home extends Vue {
  private objects = new Array<InventoryObject>();
  private usersLastPosition = new Array<UserLastPosition>();

  private selectedObject: InventoryObject | null = null;

  @Prop({required: false, default: null})
  private selectedUser!: UserLastPosition | null;

  beforeMount() {
    this.getData();
    setInterval(this.getData, 5000);
  }

  private getData() {
    fetch('/api/v1/users/track', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(async (data) => {
      return (await data.json()).usersLastPosition as Array<UserLastPosition>
    })
    .then((usersLastPosition) => {
      return usersLastPosition.map((ulp) => {
        const date = parseJSON(ulp.lastPosition.date);
        const timeZone = date.getTimezoneOffset() / 60;

        return new UserLastPosition({
          id: ulp.id,
          user: new User({
            id: ulp.user.id,
            surname: ulp.user.surname,
            firstName: ulp.user.firstName,
            middleName: ulp.user.middleName,
          }),
          lastPosition: new LastPosition({
            date: addHours(date, timeZone),
            location: new Point({
              latitude: ulp.lastPosition.location.latitude,
              longitude: ulp.lastPosition.location.longitude,
            }),
          }),
        });
      })
    })
    .then((usersLastPosition) => {
      this.usersLastPosition = usersLastPosition;
    })

    fetch('/api/v1/objects', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(async (data) => {
      return (await data.json()).objects as Array<InventoryObject>
    })
    .then((objects) => {
        return objects.map((o) => new InventoryObject({
            id: o.id,
            name: o.name,
            type: o.type,
            location: new Point({
              latitude: o.location.latitude,
              longitude: o.location.longitude,
            }),
            inventoryTasks: o.inventoryTasks.map((it) => {
              const planningDate = parseJSON(it.planningDate);
              let finishDate: Date | null = null;

              if (it.finishDate != null){
                finishDate = parseJSON(it.finishDate)
              }
              const timeZone = planningDate.getTimezoneOffset() / 60;

              return new WorkTask({
                id: it.id,
                title: it.title,
                description: it.description,
                finishDate: finishDate == null ? null : addHours(finishDate, timeZone),
                planningDate: addHours(planningDate, timeZone),
                status: it.status,
                location: new Point({
                  latitude: it.location.latitude,
                  longitude: it.location.longitude,
                }),
                user: new User({
                  id: it.user.id,
                  surname: it.user.surname,
                  firstName: it.user.firstName,
                  middleName: it.user.middleName,
                }),
              });
            })
          }))
      })
      .then((objects) => {
        this.objects = objects;
      });
  }

  objectClicked(object: InventoryObject | null) {
    this.selectedUser = null;
    this.selectedObject = object;
  }

  userClicked(user: UserLastPosition | null) {
    this.selectedObject = null;
    this.selectedUser = user;
  }

  userDetailsClosed(){
    this.selectedUser = null;
  }
}
