













import {Component, Vue} from 'vue-property-decorator';

@Component
export default class RdUserPicker extends Vue {

  private users = new Array<{id: string; name: string }>();

  beforeMount() {
    this.getUsers();
  }

  private dataChanged(data: {id: string; name: string }) {
    this.$emit('input', data)
  }

  getUsers() {
    fetch('/api/v1/users/for-select', {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
    .then(async (data) => {
      this.users = (await data.json())
    })
  }
}

