import Point from '@/models/Point';

export default class User {
    public id!: string
    public surname!: string
    public firstName!: string
    public middleName!: string
    public lastPosition: Point | null = null

    constructor(init?: Partial<User>) {
        Object.assign(this, init)
    }

    /**
     * shortFullName
     */
    public shortFullName(){
        return `${this.surname} ${this.firstName[0]}. ${this.middleName[0]}.`
    }
}
