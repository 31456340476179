



















import Vue from "vue";
import Component from "vue-class-component";
import {DataTableHeader} from "vuetify";
import User from '@/models/User';
import {Prop} from 'vue-property-decorator';
import Point from '@/models/Point';

@Component
export default class TaskPage extends Vue {

  private tableHeaders!: DataTableHeader[]

  @Prop({required: true})
  private workers!: User[]

  @Prop()
  workerSelected!: (worker: User) => void

  beforeMount(){
    this.tableHeaders = [
      {
        text: 'Фамилия',
        value: 'surname'
      },
      {
        text: 'Имя',
        value: 'firstName'
      },
      {
        text: 'Отчество',
        value: 'middleName'
      },
      {
        text: 'Последняя позиция',
        value: 'lastPosition'
      }
    ]
  }

  //Временно удален функционал
  workerClicked(user: User) {
    if (this.workerSelected != null){
      this.workerSelected(user);
    }
  }

  formatLastPosition(lastPosition: Point | null){
    if (lastPosition == null){
      return 'Не известна';
    }

    return `${lastPosition.latitude}, ${lastPosition.longitude}`;
  }

  positionClicked(user: User) {
    if (user.lastPosition != null) {
      this.workerSelected(user);
    }
  }

}
