





























































































































































































































































import {Component, Prop, Vue} from 'vue-property-decorator'
import * as L from 'leaflet'
import {DivIconOptions, Icon, IconOptions, LatLng} from 'leaflet'
import WorkTask, {WorkTaskStatuses} from '@/models/WorkTask';
import InventoryObject from '@/models/InventoryObject';
import UserLastPosition from '@/models/UserLastPosition';
import {addMinutes} from 'date-fns';
import RdDatePicker from '@/components/RdDatePicker.vue';
import RdUserPicker from '@/components/RdUserPicker.vue';

@Component({
      components: {RdUserPicker, RdDatePicker}
    })
    export default class Map extends Vue {
        private zoom = 15
        private url = 'https://tile.thunderforest.com/outdoors/{z}/{x}/{y}.png?apikey=fe7b1ab861094cff84a14c509efd8a57'
        private attribution = '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        private center = [51.196940, 71.393843]
        private currentBounds!: L.LatLngBounds
        private assignedIcon!: L.DivIcon
        private finishedIcon!: L.DivIcon
        private userIcon!: L.Icon
        private currentPopupCoordinates: LatLng | null = null
        private addObjectDialogIsOpen = false
        private addTaskDialogIsOpen = false
        private dateMenuIsOpen = false
        private newObject: { name: string; type: string; file: File | null } = { name: '', type: '', file: null}
        private newTask = new WorkTask();
        private selectedObject: InventoryObject | null = null

        private filter: number[] = [0,1,2];

        @Prop()
        objectClicked!: (object: InventoryObject | null) => void

        @Prop()
        userClicked!: (object: UserLastPosition | null) => void

        @Prop({default: new Array<InventoryObject>(), required: true})
        inventoryObjects!: Array<InventoryObject>

        @Prop({default: new Array<UserLastPosition>(), required: true})
        usersLastPosition!: Array<UserLastPosition>

        get users() {
          let filteredUsers: UserLastPosition[] = [];
          if (this.filter.includes(2)) {
            filteredUsers = this.usersLastPosition;
          }
          return filteredUsers;
        }

        get objects() {
          let filteredObjects: InventoryObject[] = [];

          if (this.filter.includes(0)) {
            const objects = this.inventoryObjects
                .filter((io) => io.inventoryTasks.some((it) => it.status === WorkTaskStatuses.assigned));
            filteredObjects = [...objects];
          }

          if (this.filter.includes(1)) {
            const objects = this.inventoryObjects
                .filter((io) => io.inventoryTasks.some((it) => it.status === WorkTaskStatuses.finished));
            filteredObjects = [...filteredObjects,...objects];
          }

          return [...filteredObjects, ...this.inventoryObjects.filter((io) => io.inventoryTasks.length === 0)];
        }

        getIcon(data: InventoryObject | UserLastPosition): Icon<IconOptions> | Icon<DivIconOptions> {
          if (data instanceof InventoryObject) {
            if (data.inventoryTasks.some((it) => it.status == WorkTaskStatuses.assigned)) {
              return this.assignedIcon;
            }

            return this.finishedIcon;
          }

          return L.divIcon({
            className: 'custom-icon text-center',
            html: `<div>
                        <p style="margin-bottom: 0;">${data.user.shortFullName()}</p>
                        <img src="img/icons/man.png" width="32" height="32">
                      </div>`
          });
        }

        mounted(): void {
          this.assignedIcon = L.divIcon({
              className: 'custom-icon text-center',
              html: `<div class="assigned-icon">
                      +
                      </div>`,
          })
          this.finishedIcon = L.divIcon({
            className: 'custom-icon text-center',
            html: `<div class="finished-icon">
                      +
                      </div>`,
          })
        }
        onReady(map: L.Map) {
            this.currentBounds = map.getBounds()
        }
        onMoveEnded(bounds: L.LatLngBounds){
            this.currentBounds = bounds
        }
        mapContextMenuClicked(event: { latlng: LatLng }) {
          this.currentPopupCoordinates = event.latlng;
          this.newObject = {name: '', type: '', file: null};
          (this.$refs as any).mapPopupLayer.mapObject.openPopup(event.latlng);
        }
        openAddObjectDialog() {
          this.addObjectDialogIsOpen = true;
          (this.$refs as any).mapPopupLayer.mapObject.closePopup();
        }
        markerClicked(data: InventoryObject | UserLastPosition | null) {
          this.currentPopupCoordinates = null;
          if (data == null){
            this.objectClicked(null);
            return;
          }

          if (this.objectClicked != null && data instanceof InventoryObject) {
            this.objectClicked(data);
          }

          if (this.objectClicked != null && data instanceof UserLastPosition) {
            this.userClicked(data);
          }
        }
        async createNewObject(){
          const formData  = new FormData();
          formData.append('object', JSON.stringify({...this.newObject, location: {
              latitude: this.currentPopupCoordinates?.lat,
              longitude: this.currentPopupCoordinates?.lng,
            }}));
          formData.append('image', this.newObject.file!);

          await fetch('/api/v1/objects', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'POST',
            body: formData,
          });
          this.addObjectDialogIsOpen = false;
        }
        objectContextMenuClicked(object: InventoryObject, event: { originalEvent: PointerEvent; latlng: LatLng }) {
          event.originalEvent.preventDefault();
          this.selectedObject = object;
          (this.$refs as any).objectPopupLayer.mapObject.openPopup(event.latlng);
        }
        async createNewTask() {
          const body = {inventoryTask: {...this.newTask, objectId: this.selectedObject!.id}};
          body.inventoryTask.planningDate = addMinutes(body.inventoryTask.planningDate, -(new Date().getTimezoneOffset()));
          await fetch('/api/v1/tasks', {
            headers: {
              'Authorization': `Bearer ${localStorage.getItem('token')}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json',
            },
            method: 'POST',
            body: JSON.stringify(body),
          });
          this.addTaskDialogIsOpen = false;
        }
        openAddTaskDialog() {
          this.addTaskDialogIsOpen = true;
          this.newTask = new WorkTask();
          this.$nextTick(() => {
            (this.$refs.taskForm as any).reset();
          });
          (this.$refs as any).objectPopupLayer.mapObject.closePopup();
        }
    }
