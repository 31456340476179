














































import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import {format, formatISO, parseISO} from 'date-fns';

@Component
export default class RdDatePicker extends Vue {

  @Prop()
  public value!: Date | null

  private dateMenuIsOpen = false
  private stringValue = ''

  beforeMount() {
    if (this.value) {
      this.stringValue = formatISO(this.value);
    }
  }

  @Watch('stringValue')
  private valueChanged(newValue: string) {
    this.$emit('input', parseISO(newValue))
  }

  get formattedDate() {
    return this.stringValue ? format(parseISO(this.stringValue), 'dd.MM.yyyy') : ''
  }

}

