import User from '@/models/User';
import LastPosition from '@/models/LastPosition';

export default class UserLastPosition {
    public id!: string
    public user!: User
    public lastPosition!: LastPosition

    constructor(init?: Partial<UserLastPosition>) {
        Object.assign(this, init)
    }
}

