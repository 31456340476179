
















































import {Component, Prop, Vue} from 'vue-property-decorator';
import {addHours, format, parseJSON} from 'date-fns';
import UserLastPosition from '@/models/UserLastPosition';
import User from '@/models/User';
import Point from '@/models/Point';
import UserTrack from '@/models/UserTrack';
import {DataTableHeader} from 'vuetify';
import MiniMap from '@/components/MiniMap.vue';
import {LatLng} from 'leaflet';

@Component({components: {
  MiniMap,
  }
})
export default class UserDetails extends Vue {

  @Prop({type: UserLastPosition, required: true})
  userPosition!: UserLastPosition

  @Prop()
  closed!: () => void

  userTracks = new Array<UserTrack>()

  selectedRows = new Array<UserTrack>();

  private tableHeaders!: DataTableHeader[]

  get latestUserTrack() {
    const latest = this.userTracks[this.userTracks.length - 1];
    return new LatLng(latest.location.latitude, latest.location.longitude);
  }

  get userInfo() {
    return this.userPosition;
  }

  get selectedTrack() {
    return this.selectedRows;
  }

  get tableSize() {
    switch (this.$vuetify.breakpoint.name) {
      case 'xs':
      case 'sm':
      case 'md':
      case 'lg':
        return '70vh';
      case 'xl':
        return '80vh';
    }

    throw Error();
  }

  beforeMount() {
    this.tableHeaders = [
      {
        text: 'Местоположение',
        value: 'location',
      },
      {
        text: 'Дата',
        value: 'date',
      },
    ];

    fetch(`/api/v1/users/${this.userPosition.user.id}/track`, {
      headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    })
        .then(async (data) => {
          return (await data.json()).userTracks as Array<UserTrack>
        }).then((usersLastPosition) => {
      return usersLastPosition.map((ulp) => {
        const date = parseJSON(ulp.date);
        const timeZone = date.getTimezoneOffset() / 60;
        return new UserTrack({
          id: ulp.id,
          date: addHours(date, timeZone),
          user: new User({
            id: ulp.user.id,
            surname: ulp.user.surname,
            firstName: ulp.user.firstName,
            middleName: ulp.user.middleName,
          }),
          location: new Point({
            latitude: ulp.location.latitude,
            longitude: ulp.location.longitude,
          }),
        });
      })
    })
    .then((usersLastPosition) => {
      this.userTracks = usersLastPosition;
    });
  }

  formatDate(date: Date): string {
    return format(date, 'dd.MM.yyyy HH:mm:ss');
  }

  closedButtonClicked() {
    if (this.closed != null){
      this.closed();
    }
  }

  rowClicked(row: { item: UserTrack; value: boolean }) {
    if (row.value){
      this.selectedRows.push(row.item);
      this.selectedRows = this.selectedRows.sort(((a, b) => a.date.getTime() - b.date.getTime()));

      if (this.selectedRows.length > 0){
        const firstSelectedTrack = this.selectedRows[0];
        const lastSelectedTrack = this.selectedRows[this.selectedRows.length - 1];

        const rowsBetween = this.userTracks.filter((ut) => {
          return ut.date > firstSelectedTrack.date && ut.date < lastSelectedTrack.date;
        });

        this.selectedRows = [...this.selectedRows, ...rowsBetween];
      }
      this.selectedRows = this.selectedRows.sort(((a, b) => a.date.getDate() - b.date.getDate()));
    } else {
      this.selectedRows = this.selectedRows.filter((r) => r != row.item);
    }
  }

  allRowSelected(row: { items: Array<UserTrack>; value: boolean }) {
    if (row.value) {
      this.rowClicked({item: row.items[0], value: true});
      this.rowClicked({item: row.items[row.items.length - 1], value: true});
    } else {
      this.clearSelection();
    }
  }

  clearSelection(){
    this.selectedRows = new Array<UserTrack>();
  }

  selectAll() {
    this.selectedRows = this.userTracks;
  }
}

