

























































































import {Component, Prop, Vue} from 'vue-property-decorator';
import WorkTask, {WorkTaskStatuses} from '@/models/WorkTask';
import InventoryObject from '@/models/InventoryObject';
import {format} from 'date-fns';
import Point from '@/models/Point';

@Component
export default class ObjectDetails extends Vue {

  @Prop({default: InventoryObject, required: true})
  object!: InventoryObject

  get obj() {
    return this.object;
  }

  get finished(): Array<WorkTask> {
    return this.obj.inventoryTasks.filter((it) => it.status == WorkTaskStatuses.finished);
  }

  get assignedTasks(): Array<WorkTask> {
    return this.obj.inventoryTasks.filter((it) => it.status == WorkTaskStatuses.assigned);
  }

  statusName(status: string): string {
    return WorkTaskStatuses.statusName(status);
  }

  getNumber(id: string): string {
    const numberPattern = /\d+/g;
    const numbers = id.match(numberPattern)!.toString().replaceAll(',', '');
    return Number(numbers.substr(0, 3)).toString();
  }

  formatDate(date: Date | null): string {
    if (date == null){
      return '';
    }

    return format(date, 'HH:mm:ss dd.MM.yyyy');
  }

  formatLocation(location: Point): string {
    return `${location.latitude}, ${location.longitude}`;
  }

}

