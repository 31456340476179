import Point from "./Point"
import User from "./User"

export default class WorkTask {
    public id!: string
    public title!: string
    public description!: string
    public location!: Point
    public user!: User
    public status!: string
    public planningDate!: Date
    public finishDate!: Date | null

    constructor(init?: Partial<WorkTask>) {
        Object.assign(this, init)
    }
}

export class WorkTaskStatuses {
    public static none = 'None'
    public static assigned = 'Assigned'
    public static inProcess = 'InProcess'
    public static finished = 'Finished'

    public static statusName(status: string): string {
        if (status == WorkTaskStatuses.assigned){
            return 'Назначено';
        }
        if (status == WorkTaskStatuses.finished) {
            return 'Завершено';
        }
        if (status == WorkTaskStatuses.inProcess) {
            return 'В работе';
        }

        return status;
    }
}
