









import {Component, Prop, Vue} from 'vue-property-decorator'
import * as L from 'leaflet'
import {LatLng} from 'leaflet'
import InventoryObject from '@/models/InventoryObject';
import UserLastPosition from '@/models/UserLastPosition';
import UserTrack from '@/models/UserTrack';

@Component
    export default class MiniMap extends Vue {
        private zoom = 15
        private url = 'https://{s}.tile.osm.org/{z}/{x}/{y}.png'
        private attribution = '&copy; <a href="https://osm.org/copyright">OpenStreetMap</a> contributors'
        private currentBounds!: L.LatLngBounds
        private userIcon!: L.Icon

        @Prop({default: () => new Array<UserTrack>(), required: false})
        userTrack!: Array<UserTrack>

        @Prop({required: true})
        center!: LatLng

        get centerComputed() {
          return this.center;
        }

        get userTrackComputed() {
          return this.userTrack.map((ut) => [ut.location.latitude, ut.location.longitude]);
        }

        mounted(): void {
            this.userIcon = L.icon({
                iconUrl: 'img/icons/man.png',
                iconSize: [32, 32]
            })
        }
        onReady(map: L.Map) {
            this.currentBounds = map.getBounds()
        }
        onMoveEnded(bounds: L.LatLngBounds){
            this.currentBounds = bounds
        }
        markerClicked(data: InventoryObject | UserLastPosition | null) {
          return null;
        }
    }
