import Point from '@/models/Point';
import WorkTask from '@/models/WorkTask';

export default class InventoryObject {
    public id!: string
    public name!: string
    public type!: string
    public location!: Point
    public inventoryTasks: Array<WorkTask> = new Array<WorkTask>()

    constructor(init?: Partial<InventoryObject>) {
        Object.assign(this, init)
    }
}
