import Point from '@/models/Point';

export default class LastPosition {
    public date!: Date
    public location!: Point

    constructor(init?: Partial<LastPosition>) {
        Object.assign(this, init)
    }
}
