import User from '@/models/User';
import Point from '@/models/Point';

export default class UserTrack {
    public id!: string
    public user!: User
    public date!: Date
    public location!: Point

    constructor(init?: Partial<UserTrack>) {
        Object.assign(this, init)
    }
}
